import React from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import ProtectedRoute from "./components/Auth/ProtectedRoute";
import ResetPasswordPage from "./components/Auth/ResetPasswordPage";
import NavBar from "./components/Home/NavBar";
import SupaLogin from "./components/Login/SupaLogin";
import { PureRedBulkImageUploadWrapper } from "./components/BulkImageUpload/PureRedBulkImageUploadWrapper";
import { PureRedSingleImageUploadWrapper } from "./components/SingleImageUpload/PureRedSingleImageUploadWrapper"
import { MarksBulkImageUploadWrapper } from "./components/BulkImageUpload/MarksBulkImageUploadWrapper";
import { MarksSingleImageUploadWrapper } from "./components/SingleImageUpload/MarksSingleImageUploadWrapper";
import GeneratorWrapper from "./components/Generator/GeneratorWrapper";
import { ProductsProvider } from "./context/GeneratorContext";
import { SearchProductUploadWrapper } from "./components/SearchProductUpload/SearchProductUploadWrapper";
import { KrogerIntakeFormWrapper } from "./components/KrogerIntakeForm/KrogerIntakeFormWrapper";
import { SyndigoCreateProductWrapper } from "./components/SyndigoCreateProduct/SyndigoCreateProductWrapper";
import { UnderConstruction } from "./components/UnderConstruction/UnderConstruction";

const App = () => {
    return (
        <div className='min-width-scroll'>
            <NavBar />
            <Routes>
                <Route path='/*' element={<div className="h-screen w-full"></div>} />
                <Route path='/login' element={<> <SupaLogin /></>} />
                <Route path='/generator/*' element={<ProductsProvider> <GeneratorWrapper /> </ProductsProvider>} />
                <Route path='/kroger-intake-form' element={<KrogerIntakeFormWrapper />} />
                <Route path='/profile/passwordReset' element={<ProtectedRoute> <ResetPasswordPage /> </ProtectedRoute>} />
                <Route path='/purered-single-image-upload' element={<ProtectedRoute> <PureRedSingleImageUploadWrapper /> </ProtectedRoute>} />
                <Route path='/purered-bulk-image-upload' element={<ProtectedRoute> <PureRedBulkImageUploadWrapper /> </ProtectedRoute>} />
                <Route path='/marks-single-image-upload' element={<ProtectedRoute> <MarksSingleImageUploadWrapper /> </ProtectedRoute>} />
                <Route path="/marks-bulk-image-upload" element={<ProtectedRoute> <MarksBulkImageUploadWrapper /> </ProtectedRoute>} />
                <Route path="/upc-search" element={<ProtectedRoute> <SearchProductUploadWrapper /> </ProtectedRoute>} />
                <Route path="/upc-create" element={<ProtectedRoute> <SyndigoCreateProductWrapper /> </ProtectedRoute>} />
                <Route path='/construction' element={<> <UnderConstruction pageTitle="/upc-create" /> </>} />
            </Routes>
            <p className='text-xs text-right'>Version {process.env.REACT_APP_GIT_TAG}</p>
        </div>
    )
}

export default App;