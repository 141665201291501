

const fieldsTemplate = (rowData) => {
    const fieldsToShow = ['size', 'upc', 'product_title', 'description', 'bullets'];

    // console.log(Object.keys(rowData))

    const fields = Object.keys(rowData) // Array of all fields in row
        .filter(key => rowData[key] !== undefined) // Remove undefined fields
        .filter(field => fieldsToShow.includes(field.toLowerCase())); // Only keep fields that should be shown

    return (
        <div className="flex align-items-center justify-content-between">
            {
                fields.length ?
                    fields.join(', ') : // Join the fields into a single string
                    <em>No fields will be updated.</em>
            }
        </div>
    );
};

export default fieldsTemplate;