import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useAuth } from "../../context/AuthProvider";
import { Toast } from "primereact/toast";
import { sendExportCSV, sendExport } from "./ExportButtonFuncs";
type ExportButtonProps = {
  products: object[],
  fileName: string,
  wordLists?: object,
  URL?: string,
  isCSV?: boolean,
  className?: string
};

export const ExportButton = ({ products, fileName, wordLists = {}, URL = '', isCSV = false, className = '' }: ExportButtonProps) => {
  const { session } = useAuth();
  const toastRef = useRef(null);

  return (
    <>
      <Toast ref={toastRef} />
      {products.length > 0 && (
        <Button
          className={"p-button-warning " + className}
          data-pr-tooltip="Excel"
          icon="pi pi-file-excel"
          label={`Export ${isCSV ? 'CSV' : 'XLSX'}`}
          onClick={async () => isCSV ? sendExportCSV(products, fileName, toastRef) : sendExport(products, wordLists, session, fileName, toastRef, URL)}
          data-testid="Export-Button"
          size='small'
        />
      )}
    </>
  );
} 