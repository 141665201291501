import { Session } from "@supabase/supabase-js";
import axios, { AxiosRequestConfig } from "axios";

type HandlePublishClickParams = {
    products: any[];
    URL: string;
    setShowPublishDialog: Function;
    MainImageOverride: string;
    session: Session;
    setPublishStatus: Function
}


export const handlePublishClick = async (params: HandlePublishClickParams) => {
    const { products, URL, setShowPublishDialog, MainImageOverride, session, setPublishStatus } = params;

    setShowPublishDialog(false);

    // Ensure products is always an array
    const productsArray = Array.isArray(products) ? products : [products];

    setPublishStatus(prevStatus => {
        const safePrevStatus = Array.isArray(prevStatus) ? prevStatus : [];
    
        // Map to track existing UPCs for quick lookup and modification
        const statusMap = new Map(safePrevStatus.map(item => [item.upc, { ...item, isLoading: false }]));
    
        // Process each product in productsArray
        productsArray.forEach(product => {
            if (statusMap.has(product.UPC)) {
                // If the product exists, set isLoading to true
                const existingProduct = statusMap.get(product.UPC);
                statusMap.set(product.UPC, { ...existingProduct, isLoading: true });
            } else {
                // If the product does not exist, add it with isLoading set to true
                statusMap.set(product.UPC, { upc: product.UPC, isLoading: true });
            }
        });
    
        // Convert the map back to an array for the state
        return Array.from(statusMap.values());
    });

    let formatProduct = productsArray.map(product => {
        return {
            "ProductName": product?.Product_Title,
            "GTIN": product?.UPC,
            "imageOverride": MainImageOverride
        }
    })

    try {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": `Bearer ${session.access_token}`
            }
        }

        const responses = await Promise.allSettled(
            formatProduct.map(product =>
                axios.post(URL + 'api/publish', { product }, config)
            )
        );

        const resultsArray = responses.map((result, index) => {
            if (result?.status === 'fulfilled') {
                return {
                    upc: formatProduct[index].GTIN,
                    response: result.value.data,  // Accessing data directly from the resolved value
                    result: 'Success'
                };
            } else {
                let resultError = result?.reason?.response?.data?.responseData || 'Publish Request Failed: Check product information '
                return {
                    upc: formatProduct[index].GTIN,
                    response: { responseData: { publishResponse: { Message: resultError } } },
                    result: 'Error'
                };
            }
        });
        
        setPublishStatus(prevStatus => {
            return prevStatus.map(item => {
                // Find the result for the current item by UPC
                const resultEntry = resultsArray.find(resultProduct => resultProduct.upc === item.upc);
                return {
                    ...item,
                    result: resultEntry?.response?.responseData?.publishResponse?.Message || resultEntry?.result || item.result // Update result, or keep existing if not found
                };
            });
        });

    } catch (e) {
        console.error('Unexpected error:', e);
    }
    finally {
        setPublishStatus((prevStatus) => {
            return prevStatus.map(item => ({
                ...item,
                isLoading: false
            }));
        });
    }
}

export const handlePublishDialog= ( products: any[], setShowPublishDialog: Function, setShowTitles: Function) => {
    setShowPublishDialog(true)

    // Ensure products is always an array
    const productsArray = Array.isArray(products) ? products : [products];

    setShowTitles(productsArray?.map((product, index) => (
        product?.Product_Title || 'Unnamed Product'
    )));
}