import { ProgressSpinner } from "primereact/progressspinner";
import { Tooltip } from "primereact/tooltip";

const statusTemplate = (rowData, status: object) => {
    // Ensure loading is always an array
    const statusArray = Array.isArray(status) ? status : [status];
    const switchKey = statusArray.find(item => item.upc === rowData.UPC);

    let statusIcon;
    
    if (switchKey?.isLoading === true) {
        statusIcon = <ProgressSpinner className='ml-2' style={{ width: '20px', height: '20px' }} strokeWidth="8" />
    } else {
        switch (switchKey?.result) {
            case 'Success':
                statusIcon = <i className="pi pi-verified text-green-500" style={{ fontSize: '1rem' }} data-cy={`Success-${rowData.UPC}`}></i>
                break;
            case undefined:
                break;
            case null:
                break;
            default:
                statusIcon = (
                    <>
                        <i
                            className="pi pi-flag-fill text-red-500 red-tooltip"
                            style={{ fontSize: '1rem' }}
                            data-cy={`Error-${rowData?.UPC}`}
                            data-pr-tooltip={Array.isArray(switchKey?.result) ? switchKey?.result.join('\n') : switchKey?.result}
                        ></i>
                        <Tooltip target={`[data-cy="Error-${rowData.UPC}"]`} position="top" className="red-tooltip" data-testid='tooltip' />
                    </>
                )
                break;
        }
    }


    return (
        <div className="flex align-items-center ml-3">
            {statusIcon}
        </div>
    );
};

export default statusTemplate;