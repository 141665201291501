import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chips } from "primereact/chips";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import { KrogerInfoBox } from "./KrogerInfoBox";

const fieldInfo = require('../../assets/intake-form-field-info.png');

const { REACT_APP_THINKHAUS_URL } = process.env

const KrogerIntakeForm = () => {
    const initialFormState = {
        UPC: [""],
        commodity: [""],
        subCommodity: [""],
        effectiveDate: null,
        endDate: null,
        field: [
            { type: "Product Title" },
            { type: "Customer Facing Size" },
            { type: "Product Description (paragraph and feature bullet points)" },
            { type: "Package Shot Image" },
            { type: "Nutrition" },
            { type: "Ingredients" },
            { type: "Allergens" },
            { type: "Category Standards" },
            { type: "Enhanced Image: MHRI" },
            { type: "Enhanced Image: Carousels" },
            { type: 'Other' }
        ],
        userName: "",
        email: "",
        role: [
            { name: "Our Brands Brand Manager" },
            { name: "Our Brands CSM/Acceleration Team" },
            { name: "Digital DCM/ADCM" },
            { name: "CFT Regulatory" },
            { name: "Legal" },
            { name: "Supplier" },
            { name: "Other" },
        ],
        specifiedRole: '',
        isHighPriority: false,
        issue: "",
        reason: "",
        files: [],
        otherEmailsToNotify: [""],
        selectedRole: { name: "" },
        selectedFields: [],
    };

    const [formState, setFormState] = useState(initialFormState);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isValidInput, setIsValidInput] = useState(true);
    const [selectedRadioBtn, setSelectedRadioBtn] = useState("UPC");
    const [error, setError] = useState(null);

    const errorRef = useRef(null);
    const fileUploadRef = useRef(null);

    // Focus on when error changes and is not null
    useEffect(() => {
        if (error && errorRef.current) {
            // Scrolls into view and sets focus
            errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            errorRef.current.focus();
        }
    }, [error]);

    const handleChange = (e) => {
        setIsValidInput(true);
        const { name, value } = e.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));

        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setIsValidInput(emailRegex.test(value));
        }
        if (name === "isHighPriority") {
            setFormState((prevState) => ({ ...prevState, isHighPriority: e.value }));
        }
    };

    const handleSelect = (e) => {
        const { name, value } = e?.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));

        // clear out specifiedRole if selectedRole option is chosen
        if (name === 'selectedRole') {
            if (formState.selectedRole.name === 'Other') {
                setFormState((prevState) => ({ ...prevState, specifiedRole: '' }));
            }
        }
    };

    const handleDateChange = (e) => {
        setFormState((prevState) => ({ ...prevState, effectiveDate: e.value }));
    };

    const handleEndDateChange = (e) => {
        setFormState((prevState) => ({ ...prevState, endDate: e.value }));
    };


    const setEffectiveDate = (effectiveDate) => {
        if (effectiveDate) {
            //Check if todays date is the effectiveDate
            const todaysDate = new Date().toDateString();  // Get today's date in string format
            const selectedDate = new Date(effectiveDate).toDateString();  // Convert e.value to date string format

            // Compare the selected date with today's date
            return (selectedDate === todaysDate) ? 'ASAP' : effectiveDate?.toISOString();
        }
        return "";
    }

    const handleRadioButtonChange = (value) => {
        setSelectedRadioBtn(value);

        setFormState((prevState) => {
            return { ...prevState, [selectedRadioBtn]: [""] };
        });
    }

    function fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const handleFileUpload = async (e) => {
        const files = Array.from(e.files); // Assuming e.target.files is the FileList

        // Convert all files to base64
        const promises = files.map(file => fileToBase64(file));
        const base64Files = await Promise.all(promises);

        // Update form state with base64 strings
        setFormState(prevState => ({
            ...prevState,
            files: base64Files.map(base64 => ({ base64 }))
        }));
    };

    const splitMultipleValues = string => string.split(/(?:, | )/g)

    const handleDymamicFields = (field, title) => {
        const items = formState?.[field];

        const handleFieldChange = (index, field, value) => {
            setFormState((prevState) => ({
                ...prevState,
                [field]: prevState[field].map((item, i) =>
                    i === index ? value : item
                ),
            }));
        };

        return (
            <div className="col-6">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className="w-full h-3rem flex align-items-center justify-content-between relative mt-4"
                    >
                        <span className="intake-form-textSpan p-float-label surface-ground">
                            {field !== 'otherEmailsToNotify' ?
                                <InputText
                                    className="w-full h-full"
                                    value={item}
                                    onChange={(e) =>
                                        handleFieldChange(index, field, e.target.value)
                                    }
                                    disabled={
                                        field !== "otherEmailsToNotify" && field !== selectedRadioBtn
                                    }
                                    data-cy-title
                                />
                                :
                                <>
                                    <Chips
                                        className="w-full h-full"
                                        value={item}
                                        onChange={(e) =>
                                            handleFieldChange(index, field, e.target.value)
                                        }
                                        disabled={
                                            field !== "otherEmailsToNotify" && field !== selectedRadioBtn
                                        }
                                    />
                                    <p className="text-xs">Press Enter after each email to confirm.</p>
                                </>
                            }
                            <label htmlFor={field}>{title}(s){field !== "otherEmailsToNotify" ? <span className="text-red-500">*</span> : ''}</label>
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    const handleSubmit = () => {
        const {
            effectiveDate,
            endDate,
            userName,
            email,
            selectedRole,
            selectedFields,
            specifiedRole,
            issue,
            reason,
            files,
            otherEmailsToNotify,
            UPC,
            commodity,
            subCommodity,
            isHighPriority
        } = formState;


        let dataIn = {
            // the Sendgrid route still expects GTIN key though client requests UPC
            GTIN: splitMultipleValues(UPC[0]),
            commodity: splitMultipleValues(commodity[0]),
            subCommodity: splitMultipleValues(subCommodity[0]),
            effectiveDate: setEffectiveDate(effectiveDate),
            endDate,
            dateRequested: new Date().toISOString().split('T')[0],
            fields: selectedFields.map(field => field.type),
            userName,
            email,
            role: selectedRole.name,
            specifiedRole,
            issue,
            reason,
            files,
            isEnhancedImage: formState.selectedFields.some(field => field.type.startsWith('Enhanced Image')),
            otherEmailsToNotify,
            isHighPriority
        };

        if (email === "" || email === null) {
            setIsConfirming(false);
            setIsValidInput(false);
            setError({ "message": "Missing email field." })
            return;
        }

        const entries = Object.entries(dataIn);
        for (const [key, value] of entries) {
            if (Array.isArray(value)) {
                dataIn = {
                    ...dataIn,
                    [key]: value.filter((item) => item !== ""),
                };
            }
        }

        setIsConfirming(false);
        setIsLoading(true);
        setError(null);

        axios({
            method: "post",
            url: REACT_APP_THINKHAUS_URL + "kroger-intake-form",
            data: dataIn,
        })
            .then((response) => {
                setShowModal(true);
                console.log("Form submitted successfully:", response.data);
            })
            .catch((error) => {
                setError(error);
                console.error("Error submitting form:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onCloseModal = ({ isAnotherIssue }) => {
        if (isAnotherIssue) {
            setFormState({
                ...initialFormState,
                userName: formState.userName,
                email: formState.email,
                selectedRole: formState.selectedRole,
            });
        } else {
            setFormState(initialFormState);
        }

        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }

        setSelectedRadioBtn("UPC");
        setShowModal(false);
    };

    const required = (<span className="text-red-500">*</span>)

    return (
        <div style={{ backgroundColor: '#084999', color: 'white' }} className="p-3" >
            <div className="container flex flex-column w-11 font-kroger mx-auto p-3">
                <div className="flex flex-row justify-content-center">
                    <h2><strong>Our Brands Change Request Intake Form</strong></h2>
                </div>
                <Tooltip target=".size-label" position="bottom" >
                    <img className="h-25rem" src={fieldInfo} alt="info on PDP fields" />
                </Tooltip>
                <KrogerInfoBox >
                    <div>
                        Please fill out the following form to request changes to Our Brands Product Detail Page
                        (PDP) fields such as product title, description, customer-facing size, and images{' '}<span className="size-label w-max"><i className="pi pi-info-circle" /></span>.
                        Changes will be reflected on site on the provided date or within 10 business days of
                        submission. Confirmation emails will be sent to you and specified relevant parties to confirm
                        receipt of your request and when the changes are implemented.
                    </div>
                </KrogerInfoBox>
                <div className="grid">
                    <p>{required}{' '} Indicates a required field.</p>
                    <div className="flex flex-column col-12 col-offset-2 mt-4" >
                        <div className="col-offset-1">
                            {
                                [{ label: "UPC", value: "UPC" }, { label: "Sub Commodity", value: "subCommodity" }, { label: "Commodity", value: "commodity" }].map(field => (
                                    <Button
                                        key={field.value}
                                        label={field.label}
                                        value={field.value}
                                        onClick={() => handleRadioButtonChange(field.value)}
                                        className={`${!(selectedRadioBtn === field.value) ? 'bg-blue-900' : 'bg-gray-200'} p-2 w-2`}
                                    />
                                ))
                            }
                        </div>
                        <div className="col-offset-1" data-cy-upc>
                            {handleDymamicFields(selectedRadioBtn, selectedRadioBtn.toUpperCase())}
                        </div>
                        <div className="w-8">
                            You can enter UPCs with or without the leading zeros. You can request changes to
                            multiple UPCs, sub-commodities, or commodities at a time by copy and pasting
                            from a spreadsheet, separating them by spaces (# # #), or commas (#,#,#).
                        </div>
                    </div>
                    <div className="col-6 mt-4" data-cy-username >
                        <span className="intake-form-textSpan">
                            <InputText
                                className="w-full h-3rem "
                                value={formState.userName}
                                onChange={handleChange}
                                name="userName"
                            />
                            <label htmlFor="userName">Name{required}</label>
                        </span>
                    </div>
                    <div className="col-6 mt-4" data-cy-email >
                        <span className="intake-form-textSpan">
                            <InputText
                                className={`w-full h-3rem  ${isValidInput ? "" : "border-2 border-red-500"
                                    }`}
                                value={formState.email}
                                onChange={handleChange}
                                name="email"
                                type="email"
                            />
                            <label htmlFor="email">Email{required}</label>
                        </span>
                    </div>
                    <div className="col-6 mt-4" >
                        <Dropdown
                            value={formState.selectedRole}
                            onChange={handleSelect}
                            options={formState.role}
                            optionLabel="name"
                            placeholder="Select Your Role*"
                            className="w-full h-3rem flex align-items-center"
                            name="selectedRole"
                            data-cy-selectedrole
                        />
                        {formState.selectedRole.name === 'Other' &&
                            <div className="mt-2">
                                <label className="mr-2">Please Specify:</label>
                                <InputText
                                    value={formState.specifiedRole}
                                    name="specifiedRole"
                                    onChange={handleChange}
                                />
                            </div>
                        }
                    </div>
                    <div className="col-6 mt-4" >
                        <MultiSelect
                            display="chip"
                            value={formState.selectedFields}
                            onChange={handleSelect}
                            options={formState.field}
                            placeholder="Select Field(s)*"
                            optionLabel="type"
                            className="w-full h-3rem flex align-items-center"
                            name="selectedFields"
                            data-cy-selectedfields
                        />
                    </div>
                    <div className="col-6 mt-4" data-cy-targetdate >
                        <Calendar
                            style={{ height: "3rem" }}
                            className="w-full"
                            value={formState.effectiveDate}
                            onSelect={handleDateChange}
                            dateFormat="yy-mm-dd"
                            showIcon
                            name="effectiveDate"
                            placeholder="Target Date For Change On Site. Select Today For ASAP*"
                        />
                    </div>
                    <div className="col-6 mt-4" data-cy-enddate >
                        <Calendar
                            style={{ height: "3rem" }}
                            className="w-full"
                            minDate={formState.effectiveDate}
                            value={formState.endDate}
                            onSelect={handleEndDateChange}
                            dateFormat="yy-mm-dd"
                            showIcon
                            name="endDate"
                            placeholder="End Date For Change On Site."
                        />
                    </div>
                    {handleDymamicFields("otherEmailsToNotify", "Other Emails To Receive Confirmation Emails")}
                    <div className="col-12 mt-3" >
                        <p className="mb-3">Write a brief description of the change you are requesting for the field(s) you selected. Include sufficient detail to reduce back-and-forth clarification.{required}</p>
                        <span className="intake-form-textSpan">
                            <InputTextarea
                                className="w-full"
                                name="issue"
                                onChange={handleChange}
                                placeholder="Description Of Change.*"
                                rows={3}
                                value={formState.issue}
                                data-cy-description
                            />
                        </span>
                    </div>
                    <div className="col-12 mt-3" >
                        <p className="mb-3">Write a brief description of WHY you are requesting this change. This helps us understand the need state and identify any larger issues that need to be solved.</p>
                        <InputTextarea
                            className="w-full "
                            name="reason"
                            onChange={handleChange}
                            placeholder="Description Of Reason."
                            rows={3}
                            value={formState.reason}
                            data-cy-reason
                        />
                    </div>
                    <div className="col-12" data-cy-fileupload >
                        <p>Optional: Upload screenshots or files to provide additional clarity on your request (i.e. circle the problem, mockup of your proposed solution).</p>
                        <FileUpload
                            ref={fileUploadRef}
                            uploadHandler={handleFileUpload}
                            name="demo[]"
                            multiple
                            maxFileSize={1000000}
                            customUpload
                            auto
                            emptyTemplate={
                                <p className="m-0">Drag and drop files to here to upload.</p>
                            }
                        />
                    </div>
                    <div className="flex align-items-center col-12 mt-4 mb-4" >
                        <Tooltip target=".pi-info-circle" />
                        <label className="font-kroger text-2xl mr-4" htmlFor="isHighPriority">
                            High Priority Request?
                            <i
                                className="ml-3 pi pi-info-circle"
                                data-pr-position="top"
                                data-pr-tooltip="High priority requests pose a legal or sales risk to the business if they are not addressed immediately. These will be sent to the top of the queue and may cause delays with other requests." />
                        </label>
                        <InputSwitch data-testid='highPrioritySwitch' className="radio-switch" name="isHighPriority" checked={formState.isHighPriority} onChange={handleChange} />

                    </div>
                    <div className="col-6 h-4rem">
                        <Button
                            severity="success"
                            className="h-full px-4"
                            onClick={() => setIsConfirming(true)}
                            label={isLoading ? "Submitting..." : "Submit"}
                            icon={isLoading ? "pi pi-spin pi-spinner" : "pi"}
                            disabled={isLoading || !isValidInput}
                            data-cy-submitform
                        />
                    </div>
                </div>
                {
                    error &&
                    <p
                        ref={errorRef}
                        style={{ color: 'red', fontSize: '16px', fontWeight: 'bold', padding: '10px', backgroundColor: '#FFD2D2', border: '2px solid red', borderRadius: '5px' }}
                        data-cy-errortext
                    >
                        {error.message}
                    </p>
                }
            </div>
            <Dialog
                header="Are You Sure?"
                visible={isConfirming}
                style={{
                    maxWidth: "350px",
                    width: "100%",
                    textAlign: "center",
                }}
                onHide={() => setIsConfirming(false)}
            >
                <div className="card flex flex-wrap flex-column gap-2 justify-content-center">
                    <div>
                        <Button
                            onClick={() => handleSubmit()}
                            icon="pi pi-check"
                            label="Yes"
                            data-cy-confirmsubmit
                        ></Button>
                        <Button
                            onClick={() => setIsConfirming(false)}
                            icon="pi pi-times"
                            label="No"
                            className="ml-2 p-button-danger"
                        ></Button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Form submitted successfully!"
                visible={showModal}
                style={{
                    maxWidth: "350px",
                    width: "100%",
                    textAlign: "center",
                }}
                onHide={() => onCloseModal({ isAnotherIssue: false })}
            >
                <div className="card flex flex-wrap flex-column gap-2 justify-content-center">
                    <p>Enter another issue?</p>
                    <div>
                        <Button
                            onClick={() => onCloseModal({ isAnotherIssue: true })}
                            icon="pi pi-check"
                            label="Yes"
                            data-cy-successyes
                        ></Button>
                        <Button
                            onClick={() => onCloseModal({ isAnotherIssue: false })}
                            icon="pi pi-times"
                            label="No"
                            className="ml-2 p-button-danger"
                        ></Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default KrogerIntakeForm;
