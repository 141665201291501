import { Dropdown } from "primereact/dropdown";
import ExcelJS from 'exceljs';
import { setSheetInState } from "../UploadButton/UploadButtonFuncs";

type DropdownSelectProps = {
    wb: ExcelJS.Workbook,
    sheet: string,
    setSheet: (value: string) => void,
    setChoosingSheet: (value: boolean) => void,
    setProducts: (value: object[]) => void,
    sheetChoices: string[],
    id?: string,
    className?: string
};

export const DropdownSelect = ({
    wb,
    sheet,
    setSheet,
    setChoosingSheet,
    setProducts,
    sheetChoices,
    id = "DropdownSelect",
    className = ''
}: DropdownSelectProps) => {
    
    const handleDropdownSelect = (e: any) => {
        const selectedSheet = wb.getWorksheet(e.value);
        setSheet(e.value);
        setChoosingSheet(false);
        setSheetInState(selectedSheet, setProducts);
    }

    return (
        <Dropdown
            className={"my-3 " + className}
            value={sheet}
            options={sheetChoices.map(name => ({ label: name, value: name }))}
            onChange={e => handleDropdownSelect(e)}
            data-testid={id}
        />
    );
};