import React, { createContext, useContext, useState, useMemo } from 'react';
import ExcelJS from 'exceljs';
const { REACT_APP_THINKHAUS_URL
} = process.env

// Create the context
const GeneratorContext = createContext(null);

interface StatusState {
    upc: number;
    isLoading: boolean;
    results: string;
}

type Template = {
    title: string;
    override_values: string[];
}

type viewState = "viewAll" | "viewUpdate" | "viewPublish" | "viewRecent";

// Create the provider component
export const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [generated, setGenerated] = useState(false);
    const [imageOverrides, setImageOverrides] = useState(Array(6).fill('Default'));
    const [publishOverride, setPublishOverride] = useState(['KEEP EXISTING IMAGE']);
    const [choosingSheet, setChoosingSheet] = useState(false);
    const [sheetChoices, setSheetChoices] = useState([]);
    const [wb, setWb] = useState<ExcelJS.Workbook | null>(null);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [resultsBrandLibrary, setResultsBrandLibrary] = useState([]);
    const [sheet, setSheet] = useState('');
    const [brandTile, setBrandTile] = useState('');
    const [updateStatus, setUpdateStatus,] = useState<StatusState[]>([]);
    const [publishStatus, setPublishStatus] = useState<StatusState[]>([]);
    const [uploadTableData, setUploadTableData] = useState([]);
    const [resultsView, setResultsView] = useState<viewState>("viewRecent");
    const [isShouldFetchResults, setIsShouldFetchResults] = useState(true);
    const [overrideTemplate, setOverrideTemplate] = useState({ title: "", values: [] });
    const [templateOptions, setTemplateOptions] = useState<Template[]>([]);
    const URL = REACT_APP_THINKHAUS_URL;

    const value = useMemo(() => ({
        products, setProducts,
        URL,
        generated, setGenerated,
        imageOverrides, setImageOverrides,
        publishOverride, setPublishOverride,
        choosingSheet, setChoosingSheet,
        sheetChoices, setSheetChoices,
        wb, setWb,
        sheet, setSheet,
        dropdownOptions, setDropdownOptions,
        resultsBrandLibrary, setResultsBrandLibrary,
        brandTile, setBrandTile,
        updateStatus, setUpdateStatus,
        publishStatus, setPublishStatus,
        uploadTableData, setUploadTableData,
        resultsView, setResultsView,
        isShouldFetchResults, setIsShouldFetchResults,
        overrideTemplate, setOverrideTemplate,
        templateOptions, setTemplateOptions
    }), [
        products, setProducts,
        URL,
        generated, setGenerated,
        imageOverrides, setImageOverrides,
        publishOverride, setPublishOverride,
        choosingSheet, setChoosingSheet,
        sheetChoices, setSheetChoices,
        wb, setWb,
        sheet, setSheet,
        dropdownOptions, setDropdownOptions,
        resultsBrandLibrary, setResultsBrandLibrary,
        brandTile, setBrandTile,
        updateStatus, setUpdateStatus,
        publishStatus, setPublishStatus,
        uploadTableData, setUploadTableData,
        resultsView, setResultsView,
        isShouldFetchResults, setIsShouldFetchResults,
        overrideTemplate, setOverrideTemplate,
        templateOptions, setTemplateOptions
    ]);

    return (
        <GeneratorContext.Provider
            value={value}>
            {children}
        </GeneratorContext.Provider>
    );
};

// Custom hook to use the ProductsContext
export const useGeneratorContext = () => useContext(GeneratorContext);
