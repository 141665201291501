import React from 'react';
import { Session } from "@supabase/supabase-js";
import axios, { AxiosRequestConfig } from "axios";
import { Tooltip } from "primereact/tooltip";

export const ProductTitles = ({ showTitles }) => {
    return (
        <div style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
            {showTitles.map((product, index) => (
                <p key={product + index} style={{ margin: 0, padding: '5px 0' }}>
                    {product || 'Unnamed Product'}
                </p>
            ))}
        </div>
    );
};

export const ProductResponses = (showTitles, responses) => {
    if (!Array.isArray(showTitles)) {
        console.error("Invalid showTitles: expected an array, received:", showTitles);
        return null;  // or return a default value, or an empty element
    }
    return (
        <div style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
            {showTitles.map((product, index) => (
                <div key={product + index} style={{ display: 'flex', alignItems: 'center', margin: '5px 0', overflow: "visible" }}>
                    <p style={{ margin: 0, padding: '5px', flexGrow: 1 }}>
                        {product || 'Unnamed Product'}
                    </p>
                    {iconSelect(responses, index)}
                </div>
            ))}
        </div>
    );
};

const iconSelect = (responses: [], index) => {
    let element;
    switch (responses[index]) {
        case 'Success':
            element = (
                <div className="icon-container" data-testid={`icon-success-${index}`}>
                    <i className="pi pi-check" style={{ fontSize: '2em', color: 'green' }}></i>
                </div>
            );
            break;

        case 'Warning: Product has not changed since last publication':
            element = (
                <div className={`icon-container icon-container-${index} my-icon-container`} data-testid={`icon-warning-${index}`}>
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2em', color: 'orange' }}></i>
                    <Tooltip className='orange-tooltip' target={`.icon-container-${index}`} content={responses[index]} />
                </div>
            );
            break;

        default:
            element = (
                <div className={`icon-container icon-container-${index} my-icon-container`} data-testid={`icon-error-${index}`}>
                    <i className="pi pi-times" style={{ fontSize: '2em', color: 'red' }}></i>
                    <Tooltip className='red-tooltip' target={`.icon-container-${index}`} content={responses[index]} />
                </div>
            );
            break;
    }
    return (element);
}

const parseBullets = (bulletsString: string): { [key: string]: string } => {
    if (bulletsString === undefined) {
        return null;
    }

    // Split by newline, remove carriage returns and trim spaces
    const bulletsArray = bulletsString.split('\n').map(bullet =>
        bullet.replace(/\r$/, '').trim() // Removes any trailing carriage return and trims the string
    );

    const bulletsJSON = bulletsArray.reduce((acc, bullet, index) => {
        if (index < 6) { // There are only bullets 1 - 6 in Syndigo
            const key = `Feature - Benefit Bullet ${index + 1}`;
            acc[key] = bullet;
        }
        return acc;
    }, {} as { [key: string]: string });

    return bulletsJSON;
}

export const handleSubmitDialog = (products: any[], setShowSubmitDialog: Function, setShowTitles: Function) => {
    setShowSubmitDialog(true)

    // Ensure products is always an array
    const productsArray = Array.isArray(products) ? products : [products];

    setShowTitles(productsArray?.map((product, index) => (
        product.Product_Title || 'Unnamed Product'
    )));
}

export const handleSubmitClick = async (products: any[], URL, setStatus: Function, setShowSubmitDialog: Function, session: Session, imageOverrides: string[]) => {
    setShowSubmitDialog(false);

    // Ensure products is always an array
    const productsArray = Array.isArray(products) ? products : [products];

    setStatus(prevStatus => {
        const safePrevStatus = Array.isArray(prevStatus) ? prevStatus : [];

        // Map to track existing UPCs for quick lookup and modification
        const statusMap = new Map(safePrevStatus.map(item => [item.upc, { ...item, isLoading: false }]));

        // Process each product in productsArray
        productsArray.forEach(product => {
            if (statusMap.has(product.UPC)) {
                // If the product exists, set isLoading to true
                const existingProduct = statusMap.get(product.UPC);
                statusMap.set(product.UPC, { ...existingProduct, isLoading: true });
            } else {
                // If the product does not exist, add it with isLoading set to true
                statusMap.set(product.UPC, { upc: product.UPC, isLoading: true });
            }
        });

        // Convert the map back to an array for the state
        return Array.from(statusMap.values());
    });

    let formatProduct = {
        products: productsArray.map(product => {
            const bullets = parseBullets(product.bullets);
            return {
                "Product Name": product?.Product_Title,
                "GTIN": product?.UPC,
                "Customer Facing Size": product?.Size,
                "Marketing Copy": product?.description,
                ...bullets,
            }
        }),
        imageOverrides: imageOverrides
    }

    try {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": `Bearer ${session.access_token}`
            }
        }

        const response = await axios.post(URL + 'api/update-products', formatProduct, config);

        const results = productsArray?.map((product, index) => {
            const result = response?.data?.error?.[product?.UPC] ? response.data.error[product.UPC] : { result: 'Success' };
            return {
                upc: product?.UPC,
                ...result
            }
        })

        setStatus(prevStatus => {
            return prevStatus.map(item => {
                const resultEntry = results.find(resultItem => resultItem.upc === item.upc);
                return {
                    ...item,
                    result: resultEntry ? resultEntry.error || resultEntry.result : item.result // Update result, or keep existing if not found
                }
            })
        })

    } catch (e) {
        console.error(e);
    } finally {
        setStatus((prevStatus) => {
            return prevStatus.map(item => ({
                ...item,
                isLoading: false
            }));
        });
    }

}