import { useNavigate } from "react-router-dom"
import Header from "../Header/Header"
import NavBar from "../Home/NavBar"
import { ImageNavigateButton } from "./ImageNavigateButton"
import MarksSingleImageUpload from "./MarksSingleImageUpload"


export const MarksSingleImageUploadWrapper = () => {
    const navigate = useNavigate();
    const handleOnClick = () => {
        navigate('/marks-bulk-image-upload');
    }

    return (
        <div style={{minWidth: '800px'}}>
            <div className='flex align-items-end justify-content-between' style={{ background: '#084999', color: 'white' }}>
                <Header />
                <ImageNavigateButton onClick={handleOnClick} buttonText="Bulk Products?" />
            </div>
            <MarksSingleImageUpload />
        </div>
    )
}
