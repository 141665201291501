
export const validateGTIN = (gtin) => {
    gtin = gtin.toString();
    if (gtin.length === 14) { //GTIN is already correct length
        return gtin;
    } else if (gtin.length !== 13) {
        return "";
    }

    let sumOdd = 0;
    let sumEven = 0;

    for (let i = 0; i < gtin.length; i++) {
        if (i % 2 === 0) {
            sumEven += parseInt(gtin[i]);
        } else {
            sumOdd += parseInt(gtin[i]);
        }
    }

    let total = (sumEven * 3) + sumOdd;
    let checkDigit = total % 10;
    if (checkDigit !== 0) {
        checkDigit = 10 - checkDigit;
    }

    return gtin + checkDigit;
}