import React, { useState, useEffect } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useAuth } from "../../context/AuthProvider";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import axios, { AxiosRequestConfig } from "axios";
import { validateGTIN } from '../../utils/SyndigoUtils.js';

import './SyndigoCreateProduct.css';
import '../../App.css';
import { Dropdown } from "primereact/dropdown";
import { KrogerInfoBox } from "../KrogerIntakeForm/KrogerInfoBox";
import { supabaseClient } from "../../config/supabase-client";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import { FileUpload } from "primereact/fileupload";
import { handleCSVUpload } from "../UploadButton/UploadButtonFuncs";
import { emptyTemplate, itemTemplate, statusTemplate } from "./SyndigoCreateProduct_Templates";
import { ProductTitles } from "../SyndigoSubmitButton/SyndigoSubmitButtonFuncs";

const { REACT_APP_THINKHAUS_URL } = process.env;

type Status = {
    isSuccess: boolean | null,
    error: string | null
}

type ProductCreate = {
    upc: string,
    title: string,
    brand: string,
    status: Status
}

export const SyndigoCreateProduct = () => {
    const [error, setError] = useState([]);
    const [upc, setUpc] = useState('');
    const [productName, setProductName] = useState('');
    const [brandName, setBrandName] = useState('');
    const [otherBrandName, setOtherBrandName] = useState('');
    const [brandOptions, setBrandOptions] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    // array of products with fields: upc, title, brand
    const [products, setProducts] = useState<ProductCreate[]>([]);

    const toast = React.useRef(null);
    const dt = React.useRef(null);
    const fileUploadRef = React.useRef(null);

    const { session, user } = useAuth();

    useEffect(() => {
        if (uploadedFile) {
            // set the internal state of the file upload component with the file in state
            fileUploadRef?.current?.setFiles([uploadedFile]);
        }

    }, [isPanelCollapsed, uploadedFile]); // runs when the import panel is toggled.

    useEffect(() => {
        const fetchBrandOptions = async () => {
            if (!user || !session) {
                console.error('"User is not authenticated or session is missing');
                return;
            }

            const { data, error } = await supabaseClient
                .from('kroger_brand_names')
                .select('brandName');

            if (error) {
                console.error("Error fetching brand tiles:", error);
            } else {
                setBrandOptions(data.map(brand => brand.brandName));
            }
        }

        if (!brandOptions.length) {
            fetchBrandOptions();
        }
    }, [brandOptions.length, session, user]);

    const handleUploadCSV = (e) => {
        const uploadedFile = e.files[0];

        // Check if the file extension is CSV
        if (uploadedFile && !uploadedFile.name.endsWith('.csv')) {
            toast.current.show({
                severity: 'error',
                summary: `IMPORT FAILED`,
                detail: ".csv is the only format accepted",
                setTimeout: 10000
            })
            return false;
        }

        // process csv
        handleCSVUpload(e, setProducts);

        //set file in state
        setUploadedFile(uploadedFile);
    }

    const rowNumber: (_: any, row: any) => number =
        (_, row) => row.rowIndex + 1

    const isFormValid = (): boolean => {
        return (isLoading || upc.length < 13 || !productName || !brandName || (brandName === 'Other...' && !otherBrandName)) as boolean;
    }

    const handlePanelToggle = (e) => {
        setIsPanelCollapsed(e.value);
    };

    const handleClear = () => {
        setBrandName('');
        setOtherBrandName('');
        setProductName('');
        setError([]);
        setUpc('');
        setProducts(null);
        setUploadedFile(null);

    }

    const handleAddClick = () => {
        const gtinCheck = validateGTIN(upc);

        if (gtinCheck === "") {
            toast.current.show({
                severity: 'error',
                summary: 'INVALID UPC',
                detail: 'UPC is not valid, please fix before submitting again',
                life: 6000
            })
            return;
        }

        addUPC(gtinCheck, productName, otherBrandName || brandName);

        //clear
        setUpc('');
        setProductName('');
    }

    const addUPC = ((upc: string, productName: string, brandName: string) => {
        setProducts((prev) => {
            return [
                ...prev || [],
                {
                    upc: upc,
                    title: productName,
                    brand: brandName,
                    status: {
                        isSuccess: null,
                        error: null
                    }
                }
            ]
        })
    })

    const createProducts = async (products) => {
        setIsLoading(true);
        let response;

        try {
            const body = {
                products
            }

            const config: AxiosRequestConfig = {
                headers: {
                    "Authorization": `Bearer ${session.access_token}`
                }
            }

            response = await axios.post(REACT_APP_THINKHAUS_URL + 'api/syndigo/create-products', body, config)

            if (response.status === 200) {
                toast.current.show({
                    severity: 'success',
                    summary: 'UPC CREATED',
                    detail: `All items successfully sent to be created.`,
                    life: 6000
                });
            } else if (response.status === 207) {
                toast.current.show({
                    severity: 'warn',
                    summary: 'SOME UPCs FAILED',
                    detail: `There was issue with some items, but others succedded`,
                    life: 6000
                });
            } else if (response.status === 202) {
                toast.current.show({
                    severity: 'error',
                    summary: 'CREATION FAILED',
                    detail: `All items submitted failed to be created.`,
                    life: 6000
                });
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: `CREATION FAILED`,
                detail: `${error?.response?.data?.message || error?.message || 'Could not retrieve error information.'}`,
                life: 6000
            })
        } finally {
            // error handling for all items
            setIsLoading(false);
            const responseData = response?.data?.responseData
            if (responseData) {
                setProducts(prev => {
                    return prev.map(product => {
                        const successProduct = responseData.success.find(p => p.upc === product.upc);
                        if (successProduct) {
                            return {
                                ...product,
                                status: {
                                    ...product.status,
                                    isSuccess: true,
                                    error: product.status?.error || null
                                }
                            };
                        } else {
                            const failedProduct = responseData.failed.find(p => p.upc === product.upc);
                            if (failedProduct) {
                                return {
                                    ...product,
                                    status: {
                                        ...product.status,
                                        isSuccess: false,
                                        error: failedProduct?.Errors || null
                                    }
                                };
                            }
                        }
                        return product;
                    });
                });
            } else {
                // TODO: add what happens when there is no response data
            }
        }
    }

    const handleCreateClick = () => {
        // TODO: Make sure GTINs are validated
        // const gtinCheck = validateGTIN(upc);

        confirmDialog({
            message: (
                <div>
                    Are you sure you want to create the following?
                    <i
                        id="info-tooltip"
                        className="pi pi-info-circle"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                    />
                    <ProductTitles showTitles={products.map(product => product.title)} />
                    <Tooltip className='orange-tooltip' target="#info-tooltip">
                        <p>
                            The following will be created with the titles <em>exactly</em> as they appear.
                        </p>
                    </Tooltip>
                </div>
            ),
            header: 'Confirmation',
            accept: () => {
                createProducts(products)
            }
        })
    }



    return (
        <div className="page-background w-full font-kroger px-4 pb-4 mb-4 h-auto">
            <div className="syndigo-create-product w-full border-3 border-round" >
                <h2 className="flex justify-content-center">Create Product in Syndigo</h2>
                <KrogerInfoBox className='mb-3' >
                    <p>
                        If a product is missing in Syndigo, use this form to create the item. The product you submit will be sent
                        to the 'Brand Library' recipient for approval. Once approved, the product will be added to Syndigo's product index
                        and listed in the active directory, where it can be updated with content.
                    </p>
                    <p>
                        To submit this form, please ensure the following:
                    </p>
                    <ul>
                        <li>A product name must be provided.</li>
                        <li>A brand name must be selected.</li>
                        <li>A valid 13-digit or 14-digit UPC must be entered.</li>
                    </ul>
                </KrogerInfoBox>

                <div className="flex justify-content-between">
                    <Panel
                        header="File Import"
                        className="w-5 mr-2 create-panel create-import"
                        toggleable
                        collapsed={isPanelCollapsed}
                        onToggle={handlePanelToggle}
                        data-testid='Import-Dropdown'
                    >
                        <FileUpload
                            accept=".csv"
                            auto
                            className="create-file-upload"
                            customUpload
                            cancelLabel="Clear"
                            onClear={handleClear}
                            chooseLabel="Browse Files"
                            mode="advanced"
                            uploadHandler={handleUploadCSV}
                            data-testid='create-upload-csv'
                            onRemove={handleClear}
                            itemTemplate={itemTemplate}
                            emptyTemplate={emptyTemplate}
                            ref={fileUploadRef}
                        />

                    </Panel>

                    <Panel
                        header="Manually Add UPC"
                        toggleable
                        className="w-7 create-panel"
                        collapsed
                        data-testid='Manual-Dropdown'
                    >
                        <div className="flex justify-content-between mb-3">
                            <div className='w-8 mr-6'>
                                <label htmlFor="brandNameDropdown">Brand Name</label>
                                <Dropdown
                                    className='w-full border-round mr-4'
                                    id="brandNameDropdown"
                                    placeholder="Select a Brand Name..."
                                    value={brandName}
                                    options={brandOptions}
                                    onChange={e => setBrandName(e.target.value)}
                                    data-testid='Brand-Name-Select'
                                />
                            </div>

                            <div className='w-full'>
                                <label htmlFor="nameinput">Product Name</label>
                                <InputText
                                    className='w-full border-round'
                                    id='nameinput'
                                    placeholder='Please insert product name...'
                                    data-testid='Product-Name-Input'
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="flex justify-content-between align-items-center" style={{ flexWrap: 'wrap' }}>
                            {brandName === 'Other...' && (
                                <div className="flex-1 mr-4">
                                    <label htmlFor='otherBrandNameInput'>Specify Brand Name:</label>
                                    <InputText
                                        className="w-full border-round "
                                        placeholder="Please type brand name..."
                                        id="otherBrandNameInput"
                                        value={otherBrandName}
                                        onChange={(e) => setOtherBrandName(e.target.value)}
                                        data-testid='Other-BrandName-Input'
                                    />
                                    <small>i.e. Private Selection</small>
                                </div>
                            )}

                            <div className="flex-1 mr-4">
                                <label htmlFor="upcinput">UPC</label>
                                <InputText
                                    className="w-full border-round"
                                    id='upcinput'
                                    keyfilter="pint"
                                    placeholder="Please enter UPC..."
                                    value={upc}
                                    onChange={(e) => setUpc(e.target.value)}
                                    data-testid='Product-UPC-Input'
                                />
                                <small>13-digit or 14-digit</small>
                            </div>

                            <div className="flex align-items-center">
                                <Button
                                    label="Add UPC"
                                    icon="pi pi-play"
                                    className="p-button-rounded p-button-success mx-2 p-2"
                                    onClick={handleAddClick}
                                    disabled={isFormValid()}
                                    severity="success"
                                    data-testid='Button-Add'
                                />
                                <Button
                                    label="Clear"
                                    icon="pi pi-times"
                                    className="p-button-rounded p-button-secondary p-2"
                                    onClick={handleClear}
                                    disabled={isLoading || (!upc && (!brandName || !otherBrandName) && !productName)}
                                    severity='danger'
                                    data-testid='Button-Clear'
                                />
                            </div>
                        </div>
                    </Panel>
                </div>

                {error.length > 1 ? <>error</> : null}

                <Toast ref={toast} data-testid="toast-component" />
                <ConfirmDialog />

                <DataTable
                    ref={dt}
                    className=" pb-6 pt-3 mt-3 max-w-full max-h-full"
                    columnResizeMode="fit"
                    emptyMessage="No UPCs have been added to be uploaded."
                    resizableColumns
                    showGridlines
                    scrollable
                    scrollHeight="65vh"
                    size="small"
                    stripedRows
                    value={products}
                    data-testid="CreateDataTable"
                >
                    <Column field="Index" header="#" body={rowNumber} className="create-table-column" />
                    <Column field="title" header='Title' className="create-table-column" />
                    <Column field="upc" header='UPC' className="create-table-column" />
                    <Column field="brand" header='Brand' />
                    <Column field="status.isSuccess" header='Status' body={rowData => statusTemplate(rowData, setProducts, isLoading)} className="status-column" />

                </DataTable>
                {products?.length > 0 &&
                    <Button label="Create Item(s)" severity="success" className="p-rounded" onClick={handleCreateClick} data-testid='Button-Create' />
                }
            </div>

        </div>
    )
}