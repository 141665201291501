import React, { useEffect, useMemo, useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { useLocation, useNavigate } from "react-router-dom";

const TabViewTools = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(0);

    // Define the routes in an array
    const routes = useMemo(() => ['/generator', '/generator/syndigo-updates', '/generator/brand-tiles'], []);

    const changeTab = (e) => {
        const route = routes[e.index] || '/generator';
        navigate(route); // Use routes array for navigation
    };

    // Effect to set the correct active tab based on the current route
    useEffect(() => {
        const index = routes.indexOf(location.pathname); // Get the index of the current route
        setActiveIndex(index !== -1 ? index : 0); // Default to index 0 if the route is not found
    }, [location, routes]);

    return (
        <TabView className="flex align-items-center justify-content-center" data-testid='Generator-TabView'
            activeIndex={activeIndex}
            onTabChange={changeTab} >
            <TabPanel header='Description Generator' />
            <TabPanel header='Syndigo Update' />
            <TabPanel header='Brand Tile Upload' />
        </TabView>
    )
}

export default TabViewTools;