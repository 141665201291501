
const upcTemplate = (rowData) => {



    return (
        <div className="flex align-items-center justify-content-between">
            <div className='flex align-items-center'>
                <span className='mr-2 p-2'>{rowData.UPC}</span>
            </div>
        </div>
    );
};

export default upcTemplate;