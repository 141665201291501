import React, { useState, useRef, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { useAuth } from "../../context/AuthProvider";
import { KrogerInfoBox } from "../KrogerIntakeForm/KrogerInfoBox";

const { REACT_APP_THINKHAUS_URL } = process.env

const MarksSingleImageUpload = () => {
  const [GTIN, setGTIN] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fileData, setFileData] = useState([]);

  const errorRef = useRef(null);

  const { session } = useAuth();

  // Focus on when error changes and is not null
  useEffect(() => {
    if (error && errorRef.current) {
      // Scrolls into view and sets focus
      errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      errorRef.current.focus();
    }
  }, [error]);

  const isSubmitDisabled =
    fileData
      .filter((item) => item?.files)
      .every((item) => item?.files?.length === 0) || isLoading;

  const fileUploadRefs = useRef<any>(
    [...Array(6)].map(() => React.createRef())
  );

  const handleGTINChange = (e) => {
    setGTIN(e.target.value);
  };

  const handleFileUpload = (index, files = []) => {
    const newFileData = [...fileData];
    newFileData[index] = {
      ...newFileData[index],
      files,
      position:
        index === 0 ? "Main Product Image" : `Detailed Product View ${index}`,
    };
    setFileData(newFileData);
  };

  const handleFieldChange = (index, newValueArray) => {
    setFileData((prevState) => {
      const newState = [...prevState];

      newState[index] = {
        ...newState[index],
        UPCs: newValueArray,
      };

      return newState;
    });
  };

  const processUploads = async (uploadPromises) => {
    let hasErrors = false;
    const errorDetails = [];

    for (let i = 0; i < uploadPromises.length; i++) {
      try {
        await uploadPromises[i](); // Execute the function that returns the promise
        console.log(`Response ${i + 1} added successfully.`);
      } catch (error) {
        console.error(`Error processing upload ${i + 1}: ${error?.response?.data}`);
        const errorMessage = `Error in Request ${i + 1}: ${error?.response?.data?.errors?.length > 0
          ? error.response.data.errors.join(', ')
          : error?.response?.data?.message || 'Error information not found'
          }`;
        errorDetails.push(errorMessage); // Collect error details with index
        hasErrors = true; // Flag that there was at least one error
      }
    }

    if (hasErrors) {
      setError([...errorDetails]); // Set all error messages collected
      throw new Error("One or more uploads failed.");
    } else {
      setError(null); // Reset error state if all were successful
      setShowModal(true); // Show modal only if all uploads succeed
      if (fileUploadRefs.current) {
        fileUploadRefs.current.forEach(refEl => refEl.current.clear());
      }
      setFileData([]); // Clear file data state
    }

    setIsLoading(false); // Set loading state to false in either case
  }

  const handleSubmit = async () => {
    setIsLoading(true);

    const validFileData = fileData.filter(
      (entry) => entry && entry.files && entry.files.length > 0
    );

    const uploadPromises = validFileData.map(
      ({ files, position, UPCs }, index) => () => {
        const formData = new FormData();

        formData.append("GTIN", GTIN);
        formData.append("position", position);

        const multiple = validFileData[index].UPCs
          ? validFileData[index].UPCs.length > 1
          : false;

        formData.append("multiple", multiple.toString());
        formData.append("UPCs", UPCs || ""); // we want to send as a string (FormData doesn't like arrays)

        files.forEach((file) => formData.append("image", file));

        const config: AxiosRequestConfig = {
          headers: {
            "Authorization": `Bearer ${session.access_token}`,
            "Content-Type": "multipart/form-data"
          }
        }

        return axios.post(
          REACT_APP_THINKHAUS_URL + "api/upload-image",
          formData,
          config
        );
      }
    );

    try {
      await processUploads(uploadPromises);
    } catch (error) {
      console.error("Error submitting forms:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ background: '#084999', color: 'white' }} className="flex flex-column w-full font-kroger px-4">
      <div className="flex flex-row justify-content-center">
        <h2>Image Upload Tool</h2>
      </div>
      <KrogerInfoBox className="mb-2 mx-8">
        The intent of this form is to put Our Brands images in the correct slots in Syndigo and push them to site. Please list the main GTIN
        the images apply to. Under each image you can include other UPC(s) if that image goes across
        multiple items. If so, then for those items you do not have to upload the same image again.
        You can enter the GTINs with or without the leading zeros. You can list multiple GTINs by copy and pasting from a spreadsheet,
        separating them by spaces (# # #), or commas (#, #, #).
      </KrogerInfoBox>
      <div className="grid">
        <div className="col-12">
          <label htmlFor="UPC">
            UPC
            <span className="text-red-500">*</span>
            {' '}
            <Tooltip target=".pi-info-circle" />
            <i
              className="pi pi-info-circle"
              data-pr-tooltip="You can enter the UPCs with or without the leading zeros. You can list multiple UPCs by copy and pasting from
               a spreadsheet, separating them by spaces (# # #), or commas (#, #, #)." />
          </label>
          <InputText
            className="w-full h-3rem mt-4"
            value={GTIN}
            onChange={handleGTINChange}
            name="UPC"
            placeholder="UPC"
            data-cy-imageupc
          />
        </div>
        {fileUploadRefs.current.map((ref, index) => (
          <div key={index} className="col-6 mt-4" data-testid={`Carousel-${index + 1}`}>
            <span className="mb-2 block">Carousel Image {index + 1}{index === 0 && '/MHRI'}</span>
            <FileUpload
              ref={ref}
              onSelect={(e) => handleFileUpload(index, e?.files)}
              name={`fileUpload${index}`}
              accept="image/*"
              maxFileSize={10000000}
              customUpload
              auto
              onRemove={() =>
                setFileData((prevData) =>
                  prevData.map((item, idx) =>
                    idx === index ? { ...item, files: [] } : item
                  )
                )
              }
              emptyTemplate={
                <p className="m-0">Drag and drop files here to upload.</p>
              }
            />
            <span className="block w-full p-float-label h-3rem my-4" data-testid={`input-${index}`} >
              <InputText
                className="w-full h-full"
                value={fileData[index]?.UPCs || []}
                onChange={(e) => handleFieldChange(index, e.target.value)}
                name="UPC"
                placeholder="UPC"
              />
              <label htmlFor="UPCs">UPC(s)</label>
            </span>
          </div>
        ))}
        <div className="col-6 w-full h-4rem">
          <Button
            className="h-full px-4 mb-4"
            severity="success"
            onClick={handleSubmit}
            label={isLoading ? "Submitting..." : "Submit"}
            icon={isLoading ? "pi pi-spin pi-spinner" : "pi"}
            disabled={isSubmitDisabled}
            data-cy-imagesubmit
          />
        </div>
      </div>

      {
        error &&
        <div
          ref={errorRef}
          style={{
            color: 'red',
            fontSize: '16px',
            fontWeight: 'bold',
            padding: '10px',
            backgroundColor: '#FFD2D2',
            border: '2px solid red',
            borderRadius: '5px'
          }}
        >
          {error.map((err, index) => (
            <React.Fragment key={'error-' + index}>
              {err}
              <br />
            </React.Fragment>
          ))}
        </div>
      }

      <Dialog
        header="Form submitted successfully!"
        visible={showModal}
        style={{
          maxWidth: "350px",
          width: "100%",
          textAlign: "center",
        }}
        onHide={() => setShowModal(false)}
      >
        <div className="card flex flex-wrap flex-column gap-2 justify-content-center">
          <div>
            <Button
              onClick={() => setShowModal(false)}
              icon="pi pi-check"
              label="Ok"
            ></Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MarksSingleImageUpload;
