import React from "react";
import { SearchProductUpload } from "./SearchProductUpload";
import Header from "../Header/Header";

export const SearchProductUploadWrapper = () => {
    return (
        <div className="min-width-scroll">
            <Header />
            <SearchProductUpload />
        </div>
    )
}