import React from "react";
import Header from "../Header/Header";
import { SyndigoCreateProduct } from "./SyndigoCreateProduct";

export const SyndigoCreateProductWrapper = () => {
    return (
        <div className="min-width-scroll create-product-min-width">
            <Header />
            <SyndigoCreateProduct />
        </div>
    )
}