import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "../../App.css";

import MainTable from '../MainTable/MainTable';
import { UploadButton } from '../UploadButton/UploadButton';
import { ExportButton } from '../ExportButton/ExportButton';
import { Loader } from '../Loader/Loader';
import { RunAllButton } from '../RunAllButton/RunAllButton';
import { DropdownSelect } from '../DropdownSelect/DropdownSelect';
import { useGeneratorContext } from '../../context/GeneratorContext';
import { ThinkhausHeaderTitle } from '../Generator/ThinkhausHeaderTitle';

const wordLists = {
    bannedWords: ['practical', 'straightforward', 'free form', 'wholesome', 'all', 'only', 'pure', 'real', 'healthy', 'boost', 'well-balanced', 'hand-crafted', 'all natural', 'natural', 'minimally precessed', 'processed', 'simple', 'protein packed', 'full of protein', 'muscle-building', 'freshness', 'fresh', 'source of energy', 'trace', 'clean', 'unadulterated', 'nutritious', 'healthiness', 'whisper', 'rich source', 'unparalleled', 'perfect', 'authentically', 'robust', 'hint', 'pinch', '100%'],
    factCheckWords: ['protein', 'energy', 'omega 3 fatty acids', 'daily value', 'per serving', 'antioxidant', 'low fat', 'lowfat', 'gluten free', 'low sodium', 'low cholesterol', 'low saturated fat', 'good source', 'excellent source', 'whole wheat', '\\blight\\b', 'reduced', 'added', 'added', 'extra', 'plus', 'fortified', 'enriched', 'more', 'less', 'high', 'rich in', 'contains', 'provides', 'lean', 'extra lean', 'high potency', 'modified', '\\bno\\b', 'free', 'zero', 'amount', 'keto', 'low carb', 'antibiotics', 'hormones', 'growth hormones', 'no sugar added', 'msg', 'cage free', 'made with', 'fresh']
}

const DescriptionGenerator = () => {
    const {
        products, setProducts,
        URL,
        setGenerated,
        choosingSheet, setChoosingSheet,
        sheetChoices, setSheetChoices,
        wb, setWb,
        sheet, setSheet
    } = useGeneratorContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const dt = useRef(null);

    return (
        <div className="container min-w-screen surface-ground">
            <div className="container w-11 min-h-screen mx-auto ">
                <div className="flex justify-content-between align-items-center mb-5">
                    <ThinkhausHeaderTitle>Product Description Generator</ThinkhausHeaderTitle>
                </div>
                <div className="flex flex-row border-3 border-500">

                    {!products.length &&
                        <UploadButton
                            products={products}
                            setProducts={setProducts}
                            setChoosingSheet={setChoosingSheet}
                            setSheet={setSheet}
                            setSheetChoices={setSheetChoices}
                            setWb={setWb}
                            choosingSheet={choosingSheet}
                            wb={wb}
                            sheet={sheet}
                            sheetChoices={sheetChoices}
                        />
                    }
                    {products.length > 0 &&
                        <>
                            <RunAllButton
                                URL={URL}
                                products={products}
                                setProducts={setProducts}
                                setLoading={setLoading}
                                setGenerated={setGenerated}
                                setError={setError}
                            />
                            <Button
                                className="p-button-danger ml-3 my-3"
                                icon="pi pi-ban"
                                label="Clear Data"
                                onClick={() => { setProducts([]); setSheet(''); setSheetChoices([]); setGenerated(false) }}
                            />
                        </>
                    }
                    <ExportButton className='my-3 ml-3' products={products} wordLists={wordLists} URL={URL} fileName='products' />
                    {sheetChoices.length > 0 && (
                        <DropdownSelect
                            wb={wb}
                            sheet={sheet}
                            setSheet={setSheet}
                            setChoosingSheet={setChoosingSheet}
                            setProducts={setProducts}
                            sheetChoices={sheetChoices}
                            className='ml-3'
                        />
                    )}

                </div>
                <MainTable
                    products={products}
                    setProducts={setProducts}
                    setLoading={setLoading}
                    setGenerated={setGenerated}
                    setError={setError}
                    wordLists={wordLists}
                    dt={dt}
                />

                <Loader loading={loading} setLoading={setLoading} />

                <Dialog className="h-12rem" header="Oops..." visible={error} closable onHide={() => setError(false)}>
                    <div className="container">
                        <p className="text-primary text-4xl">Something went wrong... Please try again.</p>
                        {/* <p className="text-primary text-4xl">{errorText}</p> */}
                    </div>
                </Dialog>

            </div>
        </div>
    );
}

export default DescriptionGenerator;
