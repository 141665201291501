import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DescriptionGenerator from '../DescriptionGenerator/DescriptionGenerator';
import { BrandTilesUploadPage } from '../BrandTiles/BrandTilesUploadPage';
import ProtectedRoute from '../Auth/ProtectedRoute';
import InternalSyndigoUpdate from '../InternalSyndigoUpdate/InternalSyndigoUpdate';
import TabViewTools from './TabViewTools';

const GeneratorWrapper = () => {

    return (
        <>
            <TabViewTools />
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <DescriptionGenerator />
                        </ProtectedRoute>}
                />
                <Route
                    path="brand-tiles"
                    element={
                        <ProtectedRoute>
                            <BrandTilesUploadPage />
                        </ProtectedRoute>}
                />
                <Route
                    path='syndigo-updates'
                    element={
                        <ProtectedRoute>
                            <InternalSyndigoUpdate />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </>
    );
};

export default GeneratorWrapper;
