import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { supabaseClient } from '../../config/supabase-client';
import './SearchProductUpload.css';
import { ExportButton } from "../ExportButton/ExportButton";

export const SearchProductUpload = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);

    const fetchResults = useCallback(async () => {
        const { data, error } = await supabaseClient
            .from('uploads')
            .select('*')
            .ilike('upc', `%${searchTerm}%`)
            .order('upload_time', { ascending: false })
            .limit(30);

        if (error) {
            console.error('Error fetching data:', error);
        } else {
            setResults(data);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm) {
            fetchResults();
        }
    }, [searchTerm, fetchResults]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div style={{ background: '#084999', color: 'white' }} className="flex flex-column w-full font-kroger px-4">
            <div className="search-product-upload border-3 border-round" >
                <h2 className="flex justify-content-center">Search Product Uploads</h2>
                <div className="p-inputgroup">
                    <InputText
                        placeholder="Search by UPC"
                        value={searchTerm}
                        onChange={handleSearch}
                        data-cy-inputtext
                    />
                    <Button
                        icon="pi pi-search"
                        className="bg-green-500 border-white border-2"
                        onClick={fetchResults}
                        data-cy-search
                    />
                </div>
                <div className="flex flex-column align-items-center">
                    <DataTable value={results} paginator rows={10} className="p-datatable-md" data-cy-table >
                        <Column field="upc" header="UPC" />
                        <Column field="upload_time" header="Upload Time" body={(rowData) => new Date(rowData.upload_time).toLocaleString()} />
                        <Column field="upload_result" header="Result" />
                        <Column field="fields_updated" header="Updated Field" />
                        <Column field="user" header="User Email" />
                    </DataTable>
                    <ExportButton className="p-button-rounded mt-3 justify-content-center" products={results} wordLists={{ bannedWords: [], factCheckWords: [] }} fileName="productUploads" isCSV data-cy-exportbutton />
                </div>
            </div>
        </div>
    );
}