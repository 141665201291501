import { Panel } from "primereact/panel";
import { Tooltip } from "primereact/tooltip";
import React, { useRef, useEffect, useCallback } from "react";
import { ImageOverrideDropdown } from "../../ImageOverride/ImageOverride";
import { Dropdown } from "primereact/dropdown";
import { supabaseClient } from "../../../config/supabase-client";

type ModifiersPanelProps = {
    imageOverrides: [];
    setImageOverrides: Function; // This is the state setter
    handleSetImageOverride: Function; // This is a custom handler function
    overrideTemplate: { title };
    setOverrideTemplate: Function;
    templateOptions: [{ title, override_values }];
    setTemplateOptions: Function;
    hideTemplate?: boolean;
    tooltipText?: string;
}

export const ModifiersPanel = ({ imageOverrides, setImageOverrides, handleSetImageOverride, overrideTemplate, setOverrideTemplate, templateOptions, setTemplateOptions, hideTemplate, tooltipText = '' }: ModifiersPanelProps) => {
    const dropdownRefs = useRef([]);
    const templateDropdownRef = useRef(null);

    const fetchTemplates = useCallback(async () => {
        const { data, error } = await supabaseClient
            .from("syndigo_override_templates")
            .select("title, override_values");

        if (data?.length) {
            setTemplateOptions(data);
        } else { //Default template options
            setTemplateOptions([{title: 'Error fetching template options...', values: ['Default', 'Default', 'Default', 'Default', 'Default', 'Default']}])
        }
    }, [templateOptions])

    useEffect(() => {
        if (!templateOptions?.length) {
            try {
                fetchTemplates();
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    const handleImageOverride = (index, value) => {
        handleSetImageOverride(index, value);
        // Set focus in timeout
        setTimeout(() => {
            if (dropdownRefs.current[index]) {
                dropdownRefs.current[index].focus();
            }
        }, 0); // 0ms timeout is used to ensure the focus happens after other UI updates are complete
    };

    const handleTemplateSelection = ({ value }) => {
        const selectedTemplate = templateOptions.find(template => template.title === value);
        if (selectedTemplate?.override_values) {
            setOverrideTemplate({ title: selectedTemplate.title });
            setImageOverrides(selectedTemplate.override_values);
        } else { // If bad data from db will just default as to not throw errors
            setOverrideTemplate({ title: selectedTemplate?.title || 'Default' });
            setImageOverrides(new Array(6).fill('Default'));
        }

        // Set focus back with a timeout
        setTimeout(() => {
            if (templateDropdownRef.current) {
                templateDropdownRef.current.focus();
            }
        }, 0); // 0ms timeout is used to ensure the focus happens after other UI updates are complete
    }

    return (
        <Panel header="Modifiers" toggleable className="py-4" data-testid='Modifier-Panel' >
            {!hideTemplate &&
                <Dropdown
                    className='align-items-center'
                    placeholder="Select a Template"
                    options={(templateOptions || []).map((template) => ({ label: template.title, value: template.title }))}
                    onChange={handleTemplateSelection}
                    value={overrideTemplate.title}
                    ref={templateDropdownRef}
                    data-testid='template-dropdown'
                />}
            <div className='container flex flex-column align-items-center justify-content-between mx-0 px-0'>
                <div className='flex flex-row flex-wrap center my-3 '>
                    {imageOverrides.map((imageOverride, index) => (
                        <div key={"Carousel " + index} className="p-col-12 p-md-4 p-sm-6 mr-2 mb-2 border-3 border-500 p-2 border-round">
                            <p className='text-xl text-primary font-main mt-1 mb-1  border-500'>
                                Carousel #{index + 1}
                                {index === 0 && tooltipText ?
                                    <>
                                        <Tooltip className='custom-tooltip' target=".pi-info-circle" />
                                        <i
                                            className="ml-3 pi pi-info-circle"
                                            data-pr-position="top"
                                            data-pr-tooltip={tooltipText} />
                                    </>
                                    : null}
                            </p>

                            <ImageOverrideDropdown
                                imageOverride={imageOverride}
                                setImageOverride={(value) => handleImageOverride(index, value)}
                                dataTestid={`ImageOverrideDropdown-${index}`}
                                addValue={index === 0 ? [{ label: 'KEEP EXISTING IMAGE', value: 'KEEP EXISTING IMAGE' }] : null}
                                ref={(el) => dropdownRefs.current[index] = el}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Panel>
    )
}