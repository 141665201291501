import { useNavigate } from "react-router-dom"
import Header from "../Header/Header"
import { ImageNavigateButton } from "./ImageNavigateButton"
import BulkImageUpload from "../BulkImageUpload/BulkImageUpload"
import { KrogerInfoBox } from "../KrogerIntakeForm/KrogerInfoBox"

const positionOptions = [
    { label: 'DPV 7', value: 'Detailed Product View 7' },
    { label: 'DPV 8', value: 'Detailed Product View 8' },
    { label: 'DPV 9', value: 'Detailed Product View 9' },
    { label: 'DPV 10', value: 'Detailed Product View 10' },
    { label: 'DPV 11', value: 'Detailed Product View 11' },
    { label: 'DPV 12', value: 'Detailed Product View 12' }
];

const uploadURL = 'api/purered-upload'


export const PureRedSingleImageUploadWrapper = () => {
    const navigate = useNavigate();
    const handleOnClick = () => {
        navigate('/purered-bulk-image-upload');
    }

    return (
        <div className="min-width-scroll">
            <div className='flex align-items-end justify-content-between' style={{ background: '#084999', color: 'white' }}>
                <Header />
                <ImageNavigateButton onClick={handleOnClick} buttonText="Bulk Products?" />
            </div>
            <BulkImageUpload positionOptions={positionOptions} uploadURL={uploadURL} isSingleProduct={true} >
                <div className="flex flex-column w-full font-kroger px-4 p-align-center">
                    <h2 className='text-center'> PureRED Image Upload (Single Product) </h2>
                    <KrogerInfoBox className='mb-3 mx-8 w-min-full justify-center overflow-auto max-h-20rem' >
                        <p>
                            If you have multiple views for a single GTIN (UPC), this upload tool will process each view
                            for the specified product. You can add files by clicking the 'Choose' button or by dragging and dropping
                            them into the table area. After adding the images and filling in all required data, click the green
                            'Upload Images to Syndigo' button to upload the images. This will update the corresponding fields in
                            the Syndigo Product Index. <br /> 
                            <b><i>Note:</i> UPCs will be automatically entered if included in the filename (<i>i.e. 0001223334444_S.jpg</i>)</b> 
                        </p>
                        <p>
                            To submit this form, please ensure the following:
                        </p>
                        <ul>
                            <li>A UPC is entered.</li>
                            <li>A position is selected for <b><i>each</i></b> product image.</li>
                        </ul>
                    </KrogerInfoBox>
                </div>
            </BulkImageUpload>
        </div>
    )
}
