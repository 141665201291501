import React, { ReactNode, useState } from 'react';
import { FileUpload, FileUploadOptions } from 'primereact/fileupload';
import axios, { AxiosRequestConfig } from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Panel } from 'primereact/panel';
import { useAuth } from "../../context/AuthProvider";
import { validateGTIN } from '../../utils/SyndigoUtils.js';
import './BulkImageUpload.css';

const { REACT_APP_THINKHAUS_URL
} = process.env

type MultiFileUploadProps = {
    positionOptions: Array<{ label: string, value: string }>,
    uploadURL: string,
    children: ReactNode,
    isSingleProduct?: boolean
};


const MultiFileUpload = ({ positionOptions, uploadURL, children, isSingleProduct = false }: MultiFileUploadProps) => {
    const { session } = useAuth();

    const [totalFileSize, setTotalFileSize] = useState(0);
    const [files, setFiles] = useState([]);
    const [globalPosition, setGlobalPosition] = useState<string>(null);
    const [globalGTIN, setGlobalGTIN] = useState<string>("");
    const [errors, setErrors] = useState({ files: [], position: false, totalFileSize: false, isGlobalGTIN: false });

    const maxSizeInMB = 100 //MB
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; //100 MB of all files

    const getGtinFromFileName = (fileName: string): string => {
        // Regex to match 13 or 14 consecutive digits
        const regex = /\d{13,14}/;
        const match1 = regex.exec(fileName);
        return match1 ? validateGTIN(match1[0]) : '';
    };

    const onBeforeSelect = (e) => {
        let event = e.originalEvent;
        let newSize = totalFileSize;

        let files = [];
        if (event.type === 'drop') {
            event = event as DragEvent;
            files = Array.from(event.dataTransfer?.files!);
        } else {
            event = event as React.ChangeEvent<HTMLInputElement>;
            files = Array.from(event.target.files!);
        }

        files.forEach(file => { // Compute sum of file sizes to add to state variable
            newSize += file.size;
        });

        if (newSize > maxSizeInBytes) {
            setErrors(prev => ({ ...prev, totalFileSize: true }));
            return false;
        } else {
            setTotalFileSize(newSize)
            setErrors({ files: [], position: false, totalFileSize: false, isGlobalGTIN: false }); // Clear any previous errors
        }
    };

    const onSelect = (e) => {
        const uploadedFiles = Array.from(e.files).map(file => ({
            fileData: file,
            gtin: !isSingleProduct && getGtinFromFileName((file as File).name),
            uploading: false,
            success: null,
            error: null,
            errorExpanded: false
        }));

        if (isSingleProduct && !globalGTIN) {
            setGlobalGTIN(getGtinFromFileName((e.files[0].name)));
        }

        setFiles(prevFiles => {
            const newFiles = uploadedFiles.filter(uploadedFile =>
                !prevFiles.some(existingFile => existingFile.fileData.name === (uploadedFile.fileData as File).name));
            return [...prevFiles, ...newFiles];
        });
    };

    const onUpload = async (e) => {
        const newErrors = { files: [], position: false, totalFileSize: false, isGlobalGTIN: false };
        if (!isSingleProduct && !globalPosition) {
            newErrors.position = true;
        }
        if (isSingleProduct && !globalGTIN) {
            newErrors.isGlobalGTIN = true;
        }
        const filesWithErrors = isSingleProduct ? files.filter(file => !file.position) : files.filter(file => !file.gtin);
        if (filesWithErrors.length > 0) {
            newErrors.files = filesWithErrors.map(file => file.fileData.name);
        }

        if (newErrors.position || newErrors.isGlobalGTIN || newErrors.files.length > 0) {
            setErrors(newErrors);
            return;
        }

        const uploadPromises = files.map(async (file) => {
            // Check the GTIN before a request is sent. Must be a 14 dig GTIN
            const gtin = isSingleProduct ? globalGTIN : file.gtin;
            if (gtin !== validateGTIN(gtin)) {
                const errorText = "GTIN number is invalid. " + (validateGTIN(gtin) !== "" ? "Suggested GTIN: " + validateGTIN(gtin) : "")
                setFiles(prevFiles => prevFiles.map(f =>
                    f.fileData.name === file.fileData.name ? { ...f, uploading: false, success: false, error: errorText } : f
                ));
                return;
            } else {
                setFiles(prevFiles => prevFiles.map(f =>
                    f.fileData.name === file.fileData.name ? { ...f, uploading: false, success: true, error: null } : f
                ));
            }

            let formGtin = file?.gtin || globalGTIN;
            let formPosition = file?.position || globalPosition;

            const formData = new FormData();
            formData.append('image', file.fileData);
            formData.append('gtin', formGtin);
            formData.append('position', formPosition);

            setFiles(prevFiles => prevFiles.map(f =>
                f.fileData.name === file.fileData.name ? { ...f, uploading: true } : f
            ));

            try {
                const config: AxiosRequestConfig = {
                    headers: {
                        "Authorization": `Bearer ${session.access_token}`,
                        "Content-Type": "multipart/form-data"
                    }
                }

                await axios.post(REACT_APP_THINKHAUS_URL + uploadURL, formData, config);

                setFiles(prevFiles => prevFiles.map(f =>
                    f.fileData.name === file.fileData.name ? { ...f, uploading: false, success: true } : f
                ));
            } catch (error) {
                let errorText = 'There was an error uploading your image.'

                if (error.response.status === 429) {
                    errorText = error.response.data.message;
                } else if (error.response.status === 500 && error?.response?.data?.errors) {
                    errorText = error?.response?.data?.errors.join(', ');
                }

                setFiles(prevFiles => prevFiles.map(f =>
                    f.fileData.name === file.fileData.name ? { ...f, uploading: false, success: false, error: errorText } : f
                ));
            }
        });

        await Promise.all(uploadPromises);
    };

    const onRemove = (file) => {
        setFiles((prevFiles) => prevFiles.filter(f => f.fileData.name !== file.file.name));
    };

    const onClear = () => {
        setFiles([]);
        setTotalFileSize(0)
        setGlobalGTIN("");
        setErrors({ files: [], position: false, totalFileSize: false, isGlobalGTIN: false })
    };

    const onDropdownChange = (e) => {
        setGlobalPosition(e.value);
        if (errors.position) {
            setErrors(prevErrors => ({ ...prevErrors, position: false }));
        }
    };

    const onGlobalGtinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setGlobalGTIN(value);

        if (errors.isGlobalGTIN) {
            setErrors(prevErrors => ({ ...prevErrors, isGlobalGTIN: false }))
        }
    }

    const onGtinChange = (e: React.ChangeEvent<HTMLInputElement>, fileToUpdate) => {
        const { value } = e.target;
        setFiles(prevFiles => prevFiles.map(f =>
            f.fileData.name === fileToUpdate.fileData.name ? { ...f, gtin: value } : f
        ));
        if (errors.files.includes(fileToUpdate.fileData.name)) {
            setErrors(prevErrors => ({ ...prevErrors, files: prevErrors.files.filter(name => name !== fileToUpdate.fileData.name) }));
        }
    };

    const onPositionChange = (e, fileToUpdate) => {
        setGlobalPosition(e.value);
        setFiles(prevFiles => prevFiles.map(f =>
            f.fileData.name === fileToUpdate.fileData.name ? { ...f, position: e.value } : f
        ));
        if (errors.files.includes(fileToUpdate.fileData.name)) {
            setErrors(prevErrors => ({ ...prevErrors, files: prevErrors.files.filter(name => name !== fileToUpdate.fileData.name) }));
        }
    }

    const cancelOptions: FileUploadOptions = {
        className: 'p-button-danger',
        icon: 'pi pi-ban'
    }

    const uploadOptions: FileUploadOptions = {
        className: 'p-button-success'
    }

    const headerTemplate = (options: any) => {
        return (
            <div>
                <div className="header-container p-d-flex p-ai-center p-2 space border-bottom-2 border-primary-500" style={{ display: 'flex', alignItems: 'center', background: 'white' }}>
                    <div className="button-group" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='' style={{ marginRight: '10px' }} data-testid='Choose-Button'>{options.chooseButton}</div>
                        <div style={{ marginRight: '10px' }} data-testid='Cancel-Button'>{options.cancelButton}</div>
                        {isSingleProduct ?
                            <InputText
                                value={globalGTIN}
                                onChange={(e) => onGlobalGtinChange(e)}
                                placeholder="Enter GTIN"
                                className={`ml-2 mr-2 ${errors.isGlobalGTIN ? 'p-invalid' : ''}`}
                                data-testid='GTIN-Input'
                            />
                            : <Dropdown
                                value={globalPosition}
                                options={positionOptions}
                                onChange={onDropdownChange}
                                placeholder="Select a position"
                                className={`ml-2 ${errors.position ? 'p-invalid' : ''}`}
                                data-testid='Position-Dropdown'
                            />}
                    </div>
                    <div className="upload-button" style={{ marginLeft: 'auto' }} data-testid='Upload-Button'>{options.uploadButton}</div>
                </div>
                {errors.totalFileSize && <Message className='flex flex-wrap' severity="error" text={`Total file size exceeds the ${maxSizeInMB} MB limit`} />}
                {errors.position && <Message className='flex flex-wrap' severity="error" text="Please select a position." />}
                {errors.isGlobalGTIN && <Message className='flex flex-wrap' severity='error' text="Please set a valid GTIN" />}
                {errors.files.length > 0 && <Message data-testid='gtin-error-message' className='flex flex-wrap' severity="error" text={`Please ${isSingleProduct ? 'select a position' : 'enter GTINs'} for all files.`} />}
            </div>
        );
    };

    const itemTemplate = (file, options) => {

        const fileInState = files.find(f => f.fileData.name === file.name);

        const renderUploadStatus = () => {
            if (fileInState?.uploading) {
                return <ProgressSpinner className='ml-2' style={{ width: '20px', height: '20px' }} strokeWidth="8" />;
            }
            if (fileInState?.success !== null) {
                if (fileInState.success) {
                    return <Message data-testid='upload-success-message' className='ml-2' severity="success" text="Upload successful" />;
                } else {
                    return (
                        <div>
                            <Panel className='ml-2 mr-2 max-w-20rem min-w-20rem custom-error-panel' header="Error uploading file" collapsed={true} toggleable>
                                <p className="m-0 text-left" data-testid="fileInState-error-text" >
                                    {fileInState.error}
                                </p>
                            </Panel>
                        </div>
                    )

                }
            }
            return null;
        };

        return (
            <div key={files.length} className={`p-d-flex p-ai-center p-jc-between mb-3 ${fileInState.error ? 'bg-red-200' : ''}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="p-d-flex p-ai-center" style={{ display: 'flex', alignItems: 'center' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={50} style={{ marginRight: '10px' }} />
                    <div className="p-ml-3">
                        <span>{file.name}</span>
                        <br />
                        <small>{(file.size / 1024 / 1024).toFixed(2)} MB</small>
                    </div>
                    {renderUploadStatus()}
                </div>
                <div className="p-d-flex p-ai-center" style={{ display: 'flex', alignItems: 'center' }}>
                    {isSingleProduct ?
                        <Dropdown
                            value={fileInState?.position}
                            options={positionOptions}
                            onChange={(e) => onPositionChange(e, fileInState)}
                            placeholder="Select a position"
                            className={`p-ml-2 ${errors.files.includes(file.name) ? 'p-invalid' : ''}`}
                            style={{ marginLeft: '10px' }}
                            data-testid='Position-Dropdown'
                        />
                        : <InputText
                            value={fileInState?.gtin}
                            onChange={(e) => onGtinChange(e, fileInState)}
                            placeholder="Enter GTIN"
                            className={`p-mr-2 ${errors.files.includes(file.name) ? 'p-invalid' : ''}`}
                            style={{ marginRight: '10px' }}
                            data-testid='GTIN-Input'
                        />}
                    <Button
                        type="button"
                        icon="pi pi-trash"
                        className="p-button-danger p-button-text"
                        onClick={() => options.onRemove(fileInState)}
                        data-testid={`Trash-${file.name}`}
                    />
                </div>
            </div>
        );
    };

    return (
        <div style={{ background: '#084999', color: 'white' }} className="flex flex-column w-full font-kroger px-4">
            <div className="flex justify-content-center">
                {children}
            </div>
            <div className='flex-container' >
                <FileUpload
                    className='border-4'
                    name="demo[]"
                    url="./upload"
                    multiple
                    accept="image/*"
                    customUpload
                    maxFileSize={10000000} // 10MB limit per file
                    chooseLabel="Choose"
                    cancelLabel="Clear All"
                    uploadLabel="Upload Images to Syndigo"
                    uploadHandler={onUpload}
                    onBeforeSelect={onBeforeSelect}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    onClear={onClear}
                    cancelOptions={cancelOptions}
                    uploadOptions={uploadOptions}
                    emptyTemplate={<p className="p-m-0">Drag and drop files here to upload.</p>}
                    itemTemplate={itemTemplate}
                    headerTemplate={headerTemplate}
                    data-testid='PR-File-Upload'
                />
            </div>
        </div>
    );
};

export default MultiFileUpload;
