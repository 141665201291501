import React from "react";
import Header from "../Header/Header";
import KrogerIntakeForm from "./KrogerIntakeForm";

export const KrogerIntakeFormWrapper = () => {
    return (
        <div className="min-width-scroll">
            <Header />
            <KrogerIntakeForm />
        </div>
    )
}