

export const handleRemoveUPC = (
    rowData,
    products, setProducts,
    resultsBrandLibrary, setResultsBrandLibrary,
    updateStatus, setUpdateStatus,
    publishStatus, setPublishStatus,
    setIsShouldFetchResults
) => {
    setProducts(products.filter(item => item !== rowData));
    setResultsBrandLibrary(resultsBrandLibrary.filter(product => product.gtin !== rowData.UPC));
    setUpdateStatus(updateStatus.filter(product => product.upc !== rowData.UPC));
    setPublishStatus(publishStatus.filter(product => product.upc !== rowData.UPC));

    setIsShouldFetchResults(true);
};
