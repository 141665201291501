import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { KrogerInfoBox } from "../KrogerIntakeForm/KrogerInfoBox";
import { Panel } from "primereact/panel";
import '../../App.css';

export const statusTemplate = (rowData, setProducts, isLoading: boolean) => {
    const switchKey = rowData?.status?.isSuccess;
    const error = rowData?.status?.error;

    let statusIcon;
    if (isLoading) {
        statusIcon = <ProgressSpinner className='ml-0' style={{ width: '20px', height: '20px' }} strokeWidth="8" />
    } else {
        switch (switchKey) {
            case true:
                statusIcon = <i className="pi pi-verified text-green-500" style={{ fontSize: '1rem' }} data-cy={`Success-${rowData.UPC}`}></i>
                break;
            case false:
                statusIcon =
                    <Panel className='max-w-20rem min-w-20rem w-20rem custom-error-panel' header="Error" collapsed={false} toggleable>
                        <p className="error-text" data-testid={`fileInState-error-text-${rowData?.upc}`} >
                            {error || "Unknown error"}
                        </p>
                    </Panel>
                break;
            default:
                statusIcon = <i className="pi pi-verified text-gray-500 opacity-40" style={{ fontSize: '1rem' }} data-cy={`nothing-${rowData.UPC}`}></i>
                break;
        }
    }

    return (
        <div className="flex align-items-center justify-content-between">
            <div>
                {statusIcon}
            </div>

            <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger p-button-text"
                onClick={() => handleRemoveUPC(rowData, setProducts)}
                data-cy-removeupc
            />
        </div>
    );
}


export const handleRemoveUPC = (rowData, setProducts) => {
    setProducts(prev => {
        return prev.filter(product => {
            return product.upc !== rowData.upc
        })
    })
}

export const itemTemplate = (file, options) => {
    return (
        <div className={`p-d-flex p-ai-center p-jc-between mb-3`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="p-d-flex p-ai-center" style={{ display: 'flex', alignItems: 'center' }}>
                <i className="pi pi-file" style={{ fontSize: '3em', color: 'green' }}></i>
                <div className="ml-3" data-testid='FileImport-FileName'>
                    <span>{file.name}</span>
                </div>
            </div>
            <div className="p-d-flex p-ai-center" style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    type="button"
                    icon="pi pi-trash"
                    className="p-button-danger p-button-text"
                    onClick={() => options.onRemove()}
                    data-testid={`Trash-${file.name}`}
                />
            </div>
        </div>
    );
}

export const emptyTemplate = () => {
    return (
        <div className="w-full p-3 ">
            <KrogerInfoBox className="overflow-auto">
            <p className="m-0 text-xs md:text-xs lg:text-xs xl:text-base">
                    Drag and drop or browse to upload a CSV file containing the following fields: <b>UPC, Title, Brand</b>.
                </p>
            </KrogerInfoBox>
        </div>
    )
}