import axios from "axios";

const { REACT_APP_GIT_TAG } = process.env;

axios.defaults.headers.common['X-App-Version'] = REACT_APP_GIT_TAG;

// Add a response interceptor to check for version mismatches
axios.interceptors.response.use(
    response => response, // Let successful responses pass through
    error => {
        if (error.response && error.response.status === 426) {
            // Notify the user about the version mismatch
            alert("A new version of this app is available. Please refresh the page to update.");

            // Optionally, you can also force a page reload immediately
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default axios; // Export the configured Axios instance