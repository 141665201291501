import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProductTitles, handleSubmitClick, handleSubmitDialog } from "./SyndigoSubmitButtonFuncs";
import "../../App.css";
import { useAuth } from "../../context/AuthProvider";
import { Toast } from "primereact/toast";

type SyndigoSubmitButtonProps = {
    products: any[],
    URL: string,
    buttonText: string,
    imageOverrides: string[],
    setStatus: Function
};

export const SyndigoSubmitButton = ({ products, URL, buttonText, imageOverrides, setStatus }: SyndigoSubmitButtonProps) => {
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const [showTitles, setShowTitles] = useState([]);

    const toast = useRef(null);

    const { session } = useAuth();

    return (
        <>
            <Toast ref={toast} />
            <Button className="p-2 p-button-rounded" onClick={() => handleSubmitDialog(products, setShowSubmitDialog, setShowTitles)} data-testid={buttonText}> {buttonText} </Button>

            <Dialog
                visible={showSubmitDialog}
                className="Syndigo-Submit-Dialog"
                onHide={() => setShowSubmitDialog(false)}
                data-cy-submit-dialog
            >
                <>
                    <div className="s-title" data-cy-s-title >Are you sure you want to submit the following to Syndigo?</div>
                    {<ProductTitles showTitles={showTitles} />}
                    <Button
                        className="p-button-primary generate-button my-3 ml-3 align-items-end"
                        icon="pi pi-check"
                        label="Submit"
                        onClick={() => handleSubmitClick(products, URL, setStatus, setShowSubmitDialog, session, imageOverrides)}
                        data-testid='submitButton1'
                        data-cy-submit-button
                    />
                    <Button
                        className="p-button-danger ml-3 my-3 align-items-end"
                        icon="pi pi-ban"
                        label="Cancel"
                        onClick={() => setShowSubmitDialog(false)}
                    />
                </>
            </Dialog>

        </>
    )
};